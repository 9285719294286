"use client"
import { ContainerSection } from './ContainerSection';
import React from 'react';
const LottieAnimation = React.lazy(() => import('./LottieAnimation'));

const features = [
  {
    classes: "bg-rose-100 border-rose-200 w-[101%]",
    icon: "ai-optimization",
    title: "Smart Auto Targeting",
    description:
      "Smartly target your audience with our Auto Audience Targeting feature, powered by advanced AI algorithms and data-driven insights.",
  },
  {
    style: { backgroundColor: '#c8fecd', borderColor: '#b7efbd' },
    icon: "map",
    title: "Location Targeting",
    description:
      "Reach the right people in the right places with Instagram Location Targeting, designed to help you grow your local presence.",
  },
  {
    classes: "bg-rose-100 border-rose-200",
    icon: "bulb",
    title: "AI Profile Optimization",
    description:
      "Optimize your Instagram profile for maximum impact with AI Instagram Profile Optimization, custom-tailored to your unique needs.",
  },
  {
    style: { backgroundColor: '#c8fecd', borderColor: '#b7efbd' },
    icon: "target",
    title: "Niche & Interest Targeting",
    description:
      "Reach your ideal audience through our precise targeting of niche markets and interests, giving you the followers who truly matter to your brand.",
  },
  {
    classes: "bg-yellow-100 border-yellow-200",

    icon: "gender",
    title: "Age & Gender Filters",
    description:
      "Filter your followers by age and gender, giving you more control over your target audience.",
  },
  {
    style: { backgroundColor: '#c8fecd', borderColor: '#b7efbd' },
    icon: "language",
    title: "Language Targeting",
    description:
      "Reach your followers in their own language with our AI Follower Language Targeting, designed to help you communicate more effectively.",
  },
  {
    classes: "bg-yellow-100 border-yellow-200",
    icon: "unicorn",
    title: "Viral Content Library",
    description:
      "Access our Viral Instagram Content Library, post ideas & templates and stay ahead of the competition with the latest trending content in your niche.",
  },
  {
    style: { backgroundColor: '#dfdeff', borderColor: '#cfceed' },
    icon: "download",
    title: "Analytics & PDF Reports",
    description:
      "Gain valuable insights with our in-depth analytics and downloadable reports. Easily track your growth with and get a comprehensive view of your performance.",
  },
  {
    classes: "bg-yellow-100 border-yellow-200",
    icon: "boost",
    title: "UpGrow Boost™",
    description:
      "Tired of slow Instagram growth? The brand new AI-assisted Boost™ feature leverages advanced pattern-recognition & smart growth activity. Our beta users have already seen a staggering 275% increase in monthly followers.",
  },
  {
    style: { backgroundColor: '#c8fecd', borderColor: '#b7efbd' },
    icon: "time",
    title: "24/7 Real-Time Growth",
    description:
      "Enjoy 24/7 Real-Time Growth and watch your Instagram account expand with our cutting-edge automation technology – even while you sleep.",
  },
  {
    style: { backgroundColor: '#dfdeff', borderColor: '#cfceed' },
    icon: "agency",
    title: "Agency-Friendly",
    description:
      "We love agencies and offer generous bulk-discounts and easy account handling for managing multiple clients.",
  },
  {
    classes: "bg-rose-100 border-rose-200",
    icon: "customer-support",
    title: "Live Chat Support",
    description:
      "Get immediate assistance from our rockstar customer success team. Our dedicated experts are always available to help you with any questions or issues you may have.",
  },
];

export default function LandingFeaturesCards() {
  return (
    <ContainerSection
      id="platform" //TODO: replace this
      aria-labelledby="platform-section"
      className="border-t border-gray-200 py-16 sm:py-24 sm:pt-[4rem] pt-5"
    >
      <div className="max-w-3xl mx-auto mt-10 text-center">
        <span className="badge-gradient-1 mb-5 inline-flex rounded-full px-4 py-2 uppercase leading-6 tracking-[0.5px] text-indigo-600">
          <span className="text-sm font-extrabold text-transparent text-gradient-1 bg-clip-text">
            Get Real Instagram Followers
          </span>
        </span>
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 md:text-5xl">
          Packed with{' '}
          <span className="text-transparent text-gradient-1 bg-clip-text">
            AI Features
          </span>{' '}
          to{' '}
          <br />
          grow your Instagram. Fast & easy.
        </h2>
        <p className="mt-6 text-xl leading-8 text-gray-600">
          Unlock the power of fast & efficient results with our powerful
          <br className='hidden lg:inline' /> AI-powered + human-assisted Instagram growth.
        </p>
      </div>

      <div className="container max-w-screen-xl mt-16 space-y-8 md:grid md:grid-cols-2 md:gap-4 md:space-y-0 lg:grid-cols-3 lg:gap-6">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`shadow-sm border-1 relative overflow-hidden rounded-3xl ${feature.classes}`}
            style={feature.style}
          >
            <div className="relative px-5 pt-6 lg:px-6">
              <LottieAnimation
                className='w-[70px] h-[70px] lg:w-[130px] lg:h-[130px] mx-auto'
                icon={feature.icon}
              />
            </div>
            <div className="px-6 py-4 text-center md:px-8 md:py-6">
              <h3 className="mb-3 text-xl font-bold md:mb-6 lg:text-2xl">
                {feature.title}
              </h3>
              <div className="max-w-screen-sm mx-auto mb-4 text-lg md:mb-10 md:text-xl text-slate-800">
                <p>{feature.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </ContainerSection>
  );
}
