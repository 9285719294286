"use client"
import { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { Container } from './Container';
import Anchor from '../Anchor/Anchor';
import { TbArrowWaveRightUp } from 'react-icons/tb';
// import LazyLoad from 'react-lazyload';
// const LottieAnimation = React.lazy(() => import('./LottieAnimation'));

const DynamicIGProfileCard = dynamic(
  () => import('@/components/presentational/tailwind/InstagramProfileCard'),
  {
    ssr: false,
    loading: () => (
      <>
        <div className="relative h-[360px] min-w-[405px] transform-gpu animate-pulse rounded-[20px] bg-gray-50 text-center shadow-2xl ring-1 ring-gray-900/10">
          {/* <Spinner className="m-auto mt-[40%]" color="purple" size="lg" /> */}
          {/* loading... */}
          {/* <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin transform-gpu dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg> */}
        </div>
      </>
    ),
  }
);

export default function LandingCustomerExamples() {

  const niches = useMemo(() => [
    {
      name: "🌍 Travel", href: "#", slug: "travel",
      ig_profile: {
        "username": "foodnetwork",
        "profile_pic_url": "/img/uploads/foodnetwork.jpg",
        "full_name": "Food Network",
        "is_verified": true,
        "follower_count": 1278913,
        "following_count": 108,
        "media_count": 10192,
        "bio": "We're serving up recipes, food news, and culinary surprises.",
        "external_url": "https://www.foodnetwork.com/"
      }
    },
    {
      name: "💪 Fitness", href: "#", slug: "fitness", ig_profile: {
        "username": "jessicaolie",
        "profile_pic_url": "/img/uploads/jessicaolie.jpg",
        "full_name": "Jessica Olie",
        "is_verified": true,
        "follower_count": 1217828,
        "following_count": 319,
        "media_count": 2223,
        "bio": "Yoga teacher & creator of the #LETSSTARTYOGA challenges. Join my online studio for on-demand yoga classes & meditations 🌙",
        "external_url": "https://www.jessicaolie.com/"
      }
    },
    {
      name: "🍔 Food", href: "#", slug: "food", ig_profile: {
        "username": "buzzfeedtasty",
        "profile_pic_url": "/img/uploads/buzzfeedtasty.jpg",
        "full_name": "Tasty",
        "is_verified": true,
        "follower_count": 3991530,
        "following_count": 348,
        "media_count": 6343,
        "bio": "Easy, delicious recipes. Tasty.co",
        "external_url": "https://tasty.co/"
      }
    },
    {
      name: "👗 Fashion", href: "#", slug: "fashion", ig_profile: {
        "username": "manrepeller",
        "profile_pic_url": "/img/uploads/manrepeller.jpg",
        "full_name": "Man Repeller",
        "is_verified": true,
        "follower_count": 863951,
        "following_count": 1033,
        "media_count": 4712,
        "bio": "Where an interest in fashion never minimizes ones intellect.",
        "external_url": "https://www.manrepeller.com/"
      }
    },
    {
      name: "💄 Beauty", href: "#", slug: "beauty", ig_profile: {
        "username": "hudabeauty",
        "profile_pic_url": "/img/uploads/hudabeauty.jpg",
        "full_name": "Huda Kattan",
        "is_verified": true,
        "follower_count": 47889170,
        "following_count": 474,
        "media_count": 16939,
        "bio": "Founder & CEO @hudabeauty",
        "external_url": "https://www.hudabeauty.com/"
      }
    },
    {
      name: "🐶 Pets",
      href: "#",
      slug: "pets",
      ig_profile: {
        "username": "dogsofinstagram",
        "profile_pic_url": "/img/uploads/dogsofinstagram.jpg",
        "full_name": "DogsOf",
        "is_verified": true,
        "follower_count": 5392668,
        "following_count": 144,
        "media_count": 7641,
        "bio": "Celebrating dogs everywhere.",
        "external_url": "https://linktr.ee/dogsofinstagram"
      }
    },
    {
      name: "🎨 Art",
      href: "#",
      slug: "art",
      ig_profile: {
        "username": "artnet",
        "profile_pic_url": "/img/uploads/artnet.jpg",
        "full_name": "Artnet",
        "is_verified": true,
        "follower_count": 384969,
        "following_count": 604,
        "media_count": 8008,
        "bio": "The Art World Online",
        "external_url": "https://www.artnet.com/"
      }
    },
    {
      name: "📷 Photography",
      href: "#",
      slug: "photography",
      ig_profile: {
        "username": "natgeophotos",
        "profile_pic_url": "/img/uploads/natgeophotos.jpg",
        "full_name": "National Geographic Photography",
        "is_verified": true,
        "follower_count": 39955484,
        "following_count": 36,
        "media_count": 12387,
        "bio": "Experience the world through the eyes of National Geographic photographers.",
        "external_url": "https://www.nationalgeographic.com/photography/"
      }
    },
    {
      name: "🎵 Music",
      href: "#",
      slug: "music",
      ig_profile: {
        "username": "billboard",
        "profile_pic_url": "/img/uploads/billboard.jpg",
        "full_name": "Billboard",
        "is_verified": true,
        "follower_count": 1398891,
        "following_count": 296,
        "media_count": 20298,
        "bio": "Music charts, news, and more.",
        "external_url": "https://www.billboard.com/"
      }
    },
    {
      name: "⚽ Sports",
      href: "#",
      slug: "sports",
      ig_profile: {
        "username": "sportscenter",
        "profile_pic_url": "/img/uploads/sportscenter.jpg",
        "full_name": "SportsCenter",
        "is_verified": true,
        "follower_count": 15884892,
        "following_count": 109,
        "media_count": 23853,
        "bio": "The official Instagram account of SportsCenter.",
        "external_url": "https://www.espn.com/watch/schedule/_/type/upcoming/"
      }
    },
    {
      name: "🎮 Gaming",
      href: "#",
      slug: "gaming",
      ig_profile: {
        "username": "ign",
        "profile_pic_url": "/img/uploads/ign.jpg",
        "full_name": "IGN",
        "is_verified": true,
        "follower_count": 1412092,
        "following_count": 177,
        "media_count": 14290,
        "bio": "We're all about video games, movies, TV, and comics!",
        "external_url": "https://www.ign.com/"
      }
    },
    {
      name: "🖥️ Technology",
      href: "#",
      slug: "technology",
      ig_profile: {
        "username": "techcrunch",
        "profile_pic_url": "/img/uploads/techcrunch.jpg",
        "full_name": "TechCrunch",
        "is_verified": true,
        "follower_count": 1052421,
        "following_count": 82,
        "media_count": 9338,
        "bio": "Breaking technology news, analysis, and opinions from TechCrunch. The number one guide for all things tech.",
        "external_url": "https://techcrunch.com/"
      }
    },
    {
      name: "💼 Business",
      href: "#",
      slug: "business",
      ig_profile: {
        "username": "forbes",
        "profile_pic_url": "/img/uploads/forbes.jpg",
        "full_name": "Forbes",
        "is_verified": true,
        "follower_count": 7805223,
        "following_count": 406,
        "media_count": 6916,
        "bio": "The homepage for Forbes' business news.",
        "external_url": "https://www.forbes.com/"
      }
    },
    {
      name: "🏠 Home decor",
      href: "#",
      slug: "home-decor",
      ig_profile: {
        "username": "hgtv",
        "profile_pic_url": "/img/uploads/hgtv.jpg",
        "full_name": "HGTV",
        "is_verified": true,
        "follower_count": 2772944,
        "following_count": 682,
        "media_count": 8581,
        "bio": "Bring the HGTV dream home to your life. Home Decorating, Interior Design, Bath & Kitchen Ideas.",
        "external_url": "https://www.hgtv.com/"
      }
    },
    {
      name: "🌱 Wellness",
      href: "#",
      slug: "wellness",
      ig_profile: {
        "username": "mindbodygreen",
        "profile_pic_url": "/img/uploads/mindbodygreen.jpg",
        "full_name": "mindbodygreen",
        "is_verified": true,
        "follower_count": 846885,
        "following_count": 223,
        "media_count": 7649,
        "bio": "Revitalize your health and well-being with simple tips for natural living, healthy recipes, mind and body advice, and more!",
        "external_url": "https://www.mindbodygreen.com/"
      }
    },
    {
      name: "😂 Memes/humor",
      href: "#",
      slug: "memes-humor",
      ig_profile: {
        "username": "9gag",
        "profile_pic_url": "/img/uploads/9gag.jpg",
        "full_name": "9GAG: Go Fun The World",
        "is_verified": true,
        "follower_count": 54170751,
        "following_count": 26,
        "media_count": 22215,
        "bio": "9GAG is your best source of fun. Get the free app on iOS & Android: 9gag.com/apps",
        "external_url": "https://9gag.com/"
      }
    },
    {
      name: "🚗 Cars", href: "#", slug: "cars", ig_profile: {
        "username": "topgear",
        "profile_pic_url": "/img/uploads/topgear.jpg",
        "full_name": "Top Gear",
        "is_verified": true,
        "follower_count": 8750000,
        "following_count": 171,
        "media_count": 7276,
        "bio": "Welcome to the official home of Top Gear on Instagram. Follow us for the latest news, photos and videos from the greatest car show on earth.",
        "external_url": "https://www.topgear.com/"
      }
    },
    {
      name: "🎬 Movies/TV", href: "#", slug: "movies-tv", ig_profile: {
        "username": "imdb",
        "profile_pic_url": "/img/uploads/imdb.jpg",
        "full_name": "IMDb",
        "is_verified": true,
        "follower_count": 1750000,
        "following_count": 0,
        "media_count": 6512,
        "bio": "The official IMDb Instagram account. Check out our Stories to get caught up on the latest movie and TV news.",
        "external_url": "https://www.imdb.com/"
      }
    }
  ], []);

  const [selectedNiche, setSelectedNiche] = useState(niches[1].slug);
  function changeNiche(niche) {
    console.log(niche)
    setSelectedNiche(niche);
    selectedIGProfile = niches.find((n) => n.slug == niche)!.ig_profile;
    console.log(selectedIGProfile)
  }

  var selectedIGProfile = niches.find((n) => n.slug == selectedNiche)!.ig_profile;
  const [activePeriod, setActivePeriod] = useState('Before');
  useEffect(() => {
    setInterval(() => {
      setActivePeriod(activePeriod == 'Before' ? 'After' : 'Before');
    }, 5500);
  }, []);
  const onLoadedProfile = (instagramProfile) => {
    console.log('loaded yoo');
    console.log(instagramProfile);
  };
  return (
    <Container className="relative z-[2] !mb-0 max-w-6xl sm:!px-24 pt-14 !pb-0 bg-gradient-to-b from-transparent via-white to-indigo-100 overflow-hidden rounded-3xl">

      <div className="max-w-4xl mx-auto text-center">
        <span className="badge-gradient-1 mb-5 inline-flex rounded-full px-4 py-2 uppercase leading-6 tracking-[0.5px] text-indigo-600">
          <span className="text-sm font-extrabold text-transparent text-gradient-1 bg-clip-text">
            Our Results
          </span>
        </span>
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 md:text-5xl">
          <span className="text-transparent text-gradient-1 bg-clip-text">
            58,980+
          </span>{' '}
          Instagrammers in all niches
        </h2>
        <p className="mt-6 text-lg leading-7 text-gray-600 lg:text-xl lg:leading-8">
          UpGrow works for any niche, from fashion to food, travel to beauty... you get it 🙃
        </p>
      </div>

      <IndustryBar />

      <div className="flex justify-center">
        <div className="relative text-center">
          {/* Before / After Toggle Switch */}
          <div className="inline-flex relative mx-auto mt-5 self-center rounded-lg bg-gray-100 p-1 py-1.5 sm:mt-10 mb-10 sm:w-auto w-full">
            {['Before', 'After'].map((period) => (
              <div key={period} className='relative w-1/2 group sm:w-auto'>
                {period === 'After' && (
                  <div className={clsx("absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur transition duration-500",
                    activePeriod === 'After' ? 'opacity-100' : 'opacity-0'
                  )} />
                )}
                <button
                  type="button"
                  className={clsx(
                    'border text-md relative ml-0.5 sm:w-auto w-full whitespace-nowrap rounded-md border-transparent py-2 font-medium transition-all duration-300 focus:z-10 focus:outline-none sm:px-8',
                    !(activePeriod === (period == 'Before' ? 'After' : 'Before'))
                      ? 'border-gray-200 bg-white text-gray-900 shadow-sm'
                      : 'border border-transparent text-gray-700 hover:bg-gray-200'
                  )}
                  onClick={() => {
                    setActivePeriod(period);
                  }}
                >
                  {period}
                </button>
              </div>
            ))}

            {/* After save doodle */}
            <span className={clsx(
              'absolute -left-full top-full flex flex-row-reverse items-end pt-2 leading-none text-green-500 transition-all duration-300 md:left-[110%] md:top-[16%] md:flex-row',
              activePeriod === 'After'
                ? '-translate-x-0 opacity-100'
                : '-translate-x-2 opacity-0'
            )}
            >
              <TbArrowWaveRightUp className='w-6 h-6 transform -scale-x-100 md:scale-x-100' />
              <Anchor href="/setup" className="text-md underline underline-offset-[3px] decoration-dotted -translate-y-1 translate-x-1 transform whitespace-nowrap font-medium italic ">
                Guaranteed growth or it's free!
              </Anchor>
            </span>
          </div>

          <div className="-m-2 mb-0 max-h-[550px] overflow-hidden rounded-xl !rounded-bl-none !rounded-br-none bg-indigo-600/5 p-2 pb-0 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-[24px] lg:p-4">
            {selectedIGProfile && (
              <DynamicIGProfileCard
                className="-mb-[8px] rounded-[20px] shadow-2xl ring-1 ring-gray-900/10"
                onLoadedProfile={onLoadedProfile}
                instagramProfile={selectedIGProfile}
                hideUsernameInput={true}
                showAfterFollowers={activePeriod === 'After'}
                isSampleAccount={true}
              />
            )}
          </div>

        </div>
      </div>


    </Container>
  );

  function IndustryBar() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a niche
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            onChange={(e) => {
              const slug = e.target.value;
              changeNiche(slug)
            }}
            className="block w-full py-2 pl-3 pr-10 my-3 text-base border-gray-300 rounded-md focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            // defaultValue={selectedNiche}
            value={selectedNiche}
          >
            {niches.map((niche) => (
              <option key={niche.slug} value={niche.slug}>
                {niche.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden pb-4 border-gray-200 sm:block border-b-1">
          <nav className="flex flex-wrap justify-center -mb-px space-x-3 overflow-x-scroll my-7 max-w-7xl" aria-label="Niche Tabs">
            {niches.map((niche) => (
              <span
                key={niche.name}
                onClick={() => changeNiche(niche.slug)}
                className={clsx(
                  'whitespace-nowrap border-b-2 py-2 my-2 px-3 text-md font-medium cursor-pointer rounded-xl transition-all duration-300',
                  niche.slug === selectedNiche
                    ? 'text-white bg-indigo-600'
                    : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-700 bg-purple-50 hover:scale-90',
                )}
                aria-current={niche.slug === selectedNiche ? 'page' : undefined}
              >
                {niche.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
    )
  }
}



