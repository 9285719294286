"use client"
import { classNames } from '@/lib/utils';
import React from 'react';
const LottieAnimation = React.lazy(() => import('./LottieAnimation'));

export default function LandingBenefits({ className }: { className?: string } = {}) {
  return (
    <div className={classNames("relative z-[2] mb-0 max-w-7xl bg-lime-50 px-7 md:px-24 pt-14 pb-20 mx-auto", className)}>
      <div className="container space-y-12 md:space-y-24">
        <header className="flex flex-col items-start">
          <p className="font-bold tracking-tight text-green-600 md:text-xl">
            Why UpGrow?
          </p>
          <h2 className="max-w-4xl mt-6 text-3xl font-bold tracking-tighter md:text-5xl lg:text-6xl">
            Grow your Instagram account.
            <br className='hidden lg:inline' />{" "}
            The smart way. {" "}
            <span className="block text-black/40">
              No bots. No spam. No gimmicks.
            </span>
          </h2>
          <p className="max-w-4xl mt-6 text-lg font-medium text-black/60 md:text-xl lg:text-2xl">
            UpGrow uses powerful AI models, 1M+ proven growth patterns,
            <br className='hidden lg:inline' /> paid advertising, micro-interactions and real-human magic
            <br className='hidden lg:inline' />to bring you next-gen social media growth.
          </p>
        </header>
        <ul className="grid gap-12 md:grid-cols-3">
          <li key="heart">
            <div className="flex items-center justify-center w-20 h-20 bg-white shadow-lg text-aqua rounded-xl shadow-black/10 ring-1 ring-black/5">
              <LottieAnimation
                style={{ width: 65, height: 65 }}
                className="drop-shadow-sm"
                icon="heart"
              />
            </div>
            <h3 className="mt-6 text-2xl font-bold tracking-tighter">
              100% Real Followers
            </h3>
            <p className="mt-1.5 text-lg text-black/60">
              Say goodbye to fake followers and bots. We guarantee that all your
              new followers are authentic, engaged, and genuinely interested in
              your content.
            </p>
          </li>
          <li key="chart">
            <div className="flex items-center justify-center w-20 h-20 bg-white shadow-lg rounded-xl text-cyan-500 shadow-black/10 ring-1 ring-black/5">
              <LottieAnimation
                style={{ width: 65, height: 65 }}
                className="drop-shadow-sm"
                icon="chart"
              />
            </div>
            <h3 className="mt-6 text-2xl font-bold tracking-tighter">
              24/7 Automatic Growth
            </h3>
            <p className="mt-1.5 text-lg text-black/60">
              Enjoy round-the-clock Instagram growth and engagement without
              lifting a finger. Our system works for you 24 hours
              <br className='hidden lg:inline' /> a day – unlike a full time employee.
            </p>
          </li>
          <li key="secure">
            <div className="flex items-center justify-center w-20 h-20 text-orange-400 bg-white shadow-lg rounded-xl shadow-black/10 ring-1 ring-black/5">
              <LottieAnimation
                style={{ width: 65, height: 65 }}
                className="drop-shadow-sm"
                icon="secure"
              />
            </div>
            <h3 className="mt-6 text-2xl font-bold tracking-tighter">
              Safe & Secure
            </h3>
            <p className="mt-1.5 text-lg text-black/60">
              UpGrow is fully Instagram-compliant. Since 2016, we've helped
              our users gain millions of followers organically.
              <br /> It’s safe and works for everyone.
            </p>
          </li>
          <li key="chat">
            <div className="flex items-center justify-center w-20 h-20 text-pink-400 bg-white shadow-lg rounded-xl shadow-black/10 ring-1 ring-black/5">
              <LottieAnimation
                style={{ width: 65, height: 65 }}
                className="drop-shadow-sm"
                icon="chat"
              />
            </div>
            <h3 className="mt-6 text-2xl font-bold tracking-tighter">
              Active & Engaging Followers
            </h3>
            <p className="mt-1.5 text-lg text-black/60">
              It's quantity + quality. UpGrow only attracts active and
              engaged followers who are eager to interact with your IG posts &
              stories.
            </p>
          </li>
          <li key="targeting">
            <div className="flex items-center justify-center w-20 h-20 bg-white shadow-lg text-mango rounded-xl shadow-black/10 ring-1 ring-black/5">
              <LottieAnimation
                style={{ width: 65, height: 65 }}
                className="drop-shadow-sm"
                icon="targeting"
              />
            </div>
            <h3 className="mt-6 text-2xl font-bold tracking-tighter">
              Smart AI-Targeting
            </h3>
            <p className="mt-1.5 text-lg text-black/60">
              Let our AI attract more followers based on your filters: location,
              age, gender, language, interests, hashtags – or let our AI do it
              automatically for you.
            </p>
          </li>
          <li key="money">
            <div className="flex items-center justify-center w-20 h-20 bg-white shadow-lg text-aqua rounded-xl shadow-black/10 ring-1 ring-black/5">
              <LottieAnimation
                style={{ width: 65, height: 65 }}
                className="drop-shadow-sm"
                icon="money"
              />
            </div>
            <h3 className="mt-6 text-2xl font-bold tracking-tighter">
              Save time, save money
            </h3>
            <p className="mt-1.5 text-lg text-black/60">
              UpGrow replaces a full social media marketing team. Focus on
              what matters for your business and let us build your Instagram
              following – automagically!
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
