import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/Anchor/Anchor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/LandingBenefits.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/LandingCustomerExamples.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/LandingFeatures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/LandingFeaturesCards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/LandingHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/LandingReviews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/upgrow/src/components/landing/LandingVideo.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
